.App {
    text-align: center;
}

.swatch {
    width: 64px;
    height: 64px;
    border-radius: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    padding: 2px;
}

.nav-item a{
    border: 1px solid rgb(75, 75, 75);
    margin: 0px 3px;
    color: rgb(75, 75, 75);
    
}

.nav-link:hover {
    color: orange;
}
.nav-link.active {
    background-color: orange !important;
}


.sym {
    border-radius: 50%;
}

.small-field {
    display: inline-block;
    background-color: greenyellow;
    border-radius: 6px;
    padding: 4px;
    margin: 2px;
}

.small-field2 {
    display: inline-block;
    background-color: rgb(240, 233, 138);
    border-radius: 6px;
    padding: 4px;
    margin: 2px;
}

.small-field3 {
    display: inline-block;
    background-color: lightgrey;
    border-radius: 6px;
    padding: 4px;
    margin: 2px;
}

.logo {
    color: rgb(75, 75, 75);
    padding: 10px;
    background-color: rgb(223, 223, 223);
    border-radius: 30px;
}

.img-small {
    width: 62px;
    height: 62px;
}

#lumina-container {
    width: 1280px;
    height: 800px;
    overflow: hidden;
}

.btn2 {
    background-color: rgb(75, 75, 75);
}

.sidebar {
    background: rgb(32, 32, 32);
    border-right-color: rgb(196, 196, 196);
    border-right-width: 5px;
    border-right-style: solid;
    min-height: 100vh;
    padding: 10px;
    text-align: center;
}

.sidebar-in {
    width: 0.16vw;
    position: fixed;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
